import { toContact } from "@/utils";
export const person = [
    {
        title: "政策服务",
        icon: require('@/assets/icon/policy.png'),
        to: "policy?active=0",
    },
    {
        title: "收费标准",
        icon: require('@/assets/icon/cost.png'),
        to: "policy?active=1",
    },
    {
        title: "在线客服 8:30-23:59",
        icon: require('@/assets/icon/service.png'),
        click: toContact,
    },
    {
        title: "设置",
        icon: require('@/assets/icon/setting.png'),
        to: "setting",
    },
]