<template>
    <div class="container">
        <main-banner :type="banner" height="43vw" />
        <main-content>
            <van-cell value="进度查询" is-link to="event">
                <template #icon>
                    <van-image
                        :src="require('@/assets/icon/search.png')"
                        width="15"
                        fit="contain"
                        class="nav-icon"
                    />
                </template>
            </van-cell>
        </main-content>
        <main-content title="常用工具" title-style="font-weight: bold">
            <van-cell
                v-for="(item, index) in person"
                :key="index"
                size="large"
                :value="item.title"
                :to="item?.to"
                is-link
                class="nav"
                @click="item?.click?.()"
            >
                <template #icon>
                    <van-image
                        :src="item.icon"
                        width="20"
                        fit="contain"
                        class="nav-icon"
                    />
                </template>
            </van-cell>
        </main-content>
    </div>
</template>

<script>
import MainBanner from "@/components/MainBanner";
import MainContent from "@/components/MainContent";
import { person } from "./json";
import settings from "@/settings";

export default {
    name: "Home",
    components: { MainBanner, MainContent },
    setup() {
        return { person, banner: settings.banner };
    },
};
</script>

<style lang="less" scoped>
.container {
    flex: 1;
    padding-bottom: 10px;
    background-color: #f2f2f2;
    .nav {
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .van-cell:after {
        border-color: #bbb;
    }
    .nav-icon {
        margin-right: 10px;
    }
}
</style>
