<template>
    <van-cell-group class="item">
        <van-cell
            v-if="title"
            :required="required"
            :title="title"
            :icon="icon"
            :value="value"
            :title-style="titleStyle"
        >
            <slot name="value" />
        </van-cell>
        <div :style="{ padding: padding ? '5px 20px' : '0' }">
            <slot />
        </div>
    </van-cell-group>
</template>

<script>
export default {
    name: "MainContent",
    props: {
        title: String,
        icon: String,
        value: String,
        required: Boolean,
        padding: Boolean,
        titleStyle: [String, Object, Array],
    },
};
</script>

<style lang="less" scoped>
.item {
    display: block;
    box-shadow: 2px 2px 8px #ddd;
    background-color: #ffffff;
    margin: 10px;
    border-radius: 5px;
    font-size: 14px;
    overflow: hidden;
}
</style>
